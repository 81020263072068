export default {
  props: {
    model: {
      default() {
        return {};
      },
    },
  },

  data: () => ({
    loading: false,
    valid: false,
  }),

  computed: {
    isEditing() {
      return this.model.id ? true : false;
    },
  },

  methods: {
    save(successMessage) {
      this.loading = true;
      const eventName = this.model.id ? 'update' : 'create';

      return this[eventName]()
        .then((response) => {
          this.$root.$toast.open(successMessage, 'success');
          this.$emit(eventName, response.data);
          this.close(eventName == 'create');
          return response.data;
        })
        .catch((error) => {
          console.error(error);
          this.$root.$toast.open('There was an error!', 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    create() {
      return this.$axios.post(this.url, this.model);
    },

    update() {
      return this.$axios.put(`${this.url}/${this.model.id}`, this.model);
    },

    close(created = false) {
      this.$refs.form.reset();
      this.$emit('close', created);
      if (this.dialog) this.dialog = false;
    },
  },
};
