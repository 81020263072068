import { cloneDeep, debounce } from 'lodash';

export default {
  data: () => ({
    data: [],
    dialog: false,
    editItem: {},
    includes: [],
    key: 0,
    loading: false,
    pagination: {
      per_page: 10,
      page: 1,
    },
    search: '',
    sortField: 'id,desc',
    totalItems: 0,
    url: '',
  }),

  methods: {
    paginate(data = {}) {
      let params = {
        offset: data.page || 1,
        limit: data.itemsPerPage || this.pagination.per_page,
      };

      if (this.includes.length > 0) {
        params.include = this.includes.toString();
      }

      if (this.search.trim() != '') {
        params.filter = this.searchQuery(this.search.trim());
      }

      params.order = this.setSort(data);

      if (this.customParams && typeof this.customParams === 'function') {
        params = this.customParams(params, data);
      }

      return this.fetch(params);
    },

    async fetch(params = {}) {
      this.loading = true;

      try {
        let result = await this.$axios.get(this.url, { params });
        this.data = result.data.rows;
        this.totalItems = result.data.count;
      } catch (error) {
        console.error(error.response);
      } finally {
        this.loading = false;
      }
    },

    query: debounce(function () {
      this.paginate(cloneDeep(this.pagination));
    }, 500),

    setSort(options) {
      if (options.sortBy && options.sortBy.length) {
        let sorts = [];
        options.sortBy.forEach((column, index) => {
          sorts.push(column + ',' + (options.sortDesc[index] ? 'desc' : 'asc'));
        });

        return sorts.join('|');
      }

      if (this.sortField != null) {
        return this.sortField;
      }

      return null;
    },

    create() {
      this.key = 'create';
      this.editItem = {};
      this.dialog = true;
    },

    update(item) {
      this.key = item.id;
      this.editItem = cloneDeep(item);
      this.dialog = true;
    },

    destroy(item) {
      this.$root.$confirm
        .open()
        .then(() => {
          this.handleDelete(item);
        })
        .catch(() => {});
    },

    async handleCreate() {
      this.reset();

      return await this.paginate(cloneDeep(this.pagination));
    },

    async handleUpdate() {
      this.reset();

      return await this.paginate(cloneDeep(this.pagination));
    },

    async handleDelete(item) {
      try {
        await this.$axios.delete(`${this.url}/${item.id}`);
        this.$root.$toast.open('Item deleted.', 'success');
      } catch (error) {
        console.error(error);
        this.$root.$toast.open('There was an error!', 'error');
      }

      return await this.paginate(cloneDeep(this.pagination));
    },

    reset(created) {
      this.dialog = false;
      this.editItem = {};

      if (created) this.key = 0;
    },
  },
};
